import * as React from "react";

import Layout from "../../containers/jp/layout";
import Seo from "../../components/seo";

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
  </Layout>
);

export default IndexPage;
