import React from "react";
import NavMenuRequestDemo from "../components/NavMenuRequestDemo";

import imageProductMenu from "../images/product_menu.png";
import imageSolutionsMenu from "../images/solutions_menu.png";
import imageMenuResource from "../images/menu_resource.png";
import imageAboutMenu from "../images/about_menu.png";
import imageWorldWide from "../images/worldwide.png";
import imageOnlineCoRoundCorder from "../images/imageonline-co-roundcorner.png";

export const menuItems = [
  {
    name: "entomo",
    component: "entomo",
    title: "entomo",
    img: imageSolutionsMenu,
    action: {
      name: "すべて表示",
      url: "/jp/all-products/entomo/",
    },
    sub: [
      {
        name: "perform",
        description: "日々の業務を評価し、促進する",
        url: "/jp/all-products/entomo/#perform",
      },
      {
        name: "engage",
        description: "チーム内の参加を促進する",
        url: "/jp/all-products/entomo/#engage",
      },
      {
        name: "nudge",
        description: "個人やチームのゴールにとってパーソナライズ化されたインサイト",
        url: "/jp/all-products/entomo/#nudge",
      },
      {
        name: "act",
        description: "分散チームとリモートチームを管理しよう",
        url: "/jp/all-products/entomo/#act",
      },
      {
        name: "grow",
        description: "明日に向けた戦力を構築する",
        url: "/jp/all-products/entomo/#grow",
      },
      {
        name: "特徴",
        description: "*セルフサービスとして利用可能",
        url: "/jp/all-products/pre-configured-editions/",
      },
    ],
  },
  {
    name: "ソリューション",
    component: "ソリューション",
    title: "ソリューション",
    url: "/jp/solutions/",
    img: imageProductMenu,
    action: {
      name: "すべて表示",
      url: "/jp/solutions/",
    },
    sub: [
      {
        name: "everyday performance",
        url: "/jp/solutions/everyday-performance/",
      },
      {
        name: "everyday insights",
        url: "/jp/solutions/everyday-insights/",
      },
      {
        name: "careers tomorrow",
        url: "/jp/solutions/careers-tomorrow/",
      },
      {
        name: "first safety",
        url: "/jp/solutions/workplace-safety/",
      },
    ],
  },
  {
    name: "インスピレーション",
    component: "インスピレーション",
    title: "インスピレーション",
    img: imageMenuResource,
    action: {
      name: "すべて表示",
      url: "/jp/resources/",
    },
    sub: [
      {
        name: "ブログ",
        url: "/jp/resources/blog/",
      },
      {
        name: "Ebook・ホワイトペーパー",
        url: "/jp/resources/whitepaper-e-book/",
      },
      {
        name: "イベント・ウェビナー",
        url: "/jp/resources/events-webinar/",
      },
      {
        name: "サクセスストーリー",
        url: "/jp/resources/success-stories/",
      },
    ],
  },
  {
    name: "私たちについて",
    component: "私たちについて",
    title: "私たちについて",
    img: imageAboutMenu,
    sub: [
      {
        name: "なぜentomoが選ばれるのか",
        url: "/jp/about/why-entomo/",
      },
      {
        name: "私たちのチーム",
        url: "/jp/about/our-team/",
      },
      {
        name: "enotomoコミュニティー",
        url: "/jp/entomo-community/",
      },
      {
        name: "ニュース・プレスリリース",
        url: "/jp/news-press-releases/",
      },
    ],
  },
  {
    name: "連絡先",
    component: "連絡先",
    url: "/jp/contact/",
  },
  {
    name: "デモをリクエスト",
    component: <NavMenuRequestDemo text="デモをリクエスト" url={"/jp/request-demo/"} />,
  },
  {
    name: "regional presense",
    component: <img src={imageWorldWide} alt="worldwide" className="h-6 lg:h-7" />,
    img: imageOnlineCoRoundCorder,
    sub: [
      {
        name: "Middle East & Africa",
        url: "/en-mea/",
      },
      {
        name: "Indonesia",
        url: "/id/",
      },
      {
        name: "Global",
        url: "/",
      },
    ],
  },
];
