const regions = [
  {
    id: 1,
    country: "Asia",
    addressLines: ["No 60 Paya Lebar Road", "#11-06 Paya Lebar Square", "Singapore 409051"],
    tel: "Tel:+65631381767",
  },
  {
    id: 2,
    country: "Middle East",
    addressLines: ["The Onyx Tower 1", "Office 910", "P.O.BOx 410870.", "Dubai,United Arab Emirates"],
    tel: "Tel: +971 4399 52 53",
  },
  {
    id: 3,
    country: "United Sates",
    addressLines: ["2700 POst Oak Blvd", "21st Floor", "Houston,TX 77056"],
    tel: "Tel:+1(800)9478211",
  },
  {
    id: 4,
    country: "Europe",
    addressLines: ["Taubstummengasse", "7A-1040 Vienna"],
    tel: "Tel:+4317866318",
  },
];

const innovationCenters = [
  {
    id: 1,
    country: "Malaysia",
    addressLines: ["Unit 27-13, Level 27, Q Sentral,", "Jalan Stesen Sentral 2,", "50470, Kuala Lumpur,", "Malaysia"],
    tel: "Tel:+60322760055",
  },
  {
    id: 2,
    country: "India",
    addressLines: [
      "13th Cross,Sampige Road",
      "4th Floor,#218 JP Royale,",
      "Malleshwaram,Bengaluru",
      "Karnataka 560003",
    ],
    tel: "Tel:91 807 127 9515",
  },
];

const solutions = [
  {
    id: 1,
    title: "ソリューション",
    contents: [
      {
        text: "everyday performance",
        link: "https://webuat.entomo.co/jp/solutions/everyday-performance/",
      },
      {
        text: "first safety",
        link: "https://webuat.entomo.co/jp/solutions/workplace-safety/",
      },
      {
        text: "everyday insights",
        link: "https://webuat.entomo.co/jp/solutions/everyday-insights/",
      },
      {
        text: "carrers tomorrow",
        link: "https://webuat.entomo.co/jp/solutions/careers-tomorrow/",
      },
    ],
  },
  {
    id: 4,
    title: "entomo",
    contents: [
      {
        text: "プラットフォーム",
        link: "https://entomo.co/jp/all-products/entomo/",
      },
      {
        text: "特徴",
        link: "https://entomo.co/jp/all-products/pre-configured-editions/",
      },
    ],
  },
];

const inspirations = [
  {
    id: 2,
    title: "インスピレーション",
    contents: [
      {
        text: "ブログ",
        link: "https://entomo.co/jp/resources/blog/",
      },
      {
        text: "イベント・ウェビナー",
        link: "https://entomo.co/jp/resources/events-webinar/",
      },
      {
        text: "サクセスストーリー",
        link: "https://entomo.co/jp/resources/success-stories/",
      },
      {
        text: "ニュース・プレスリリース",
        link: "https://entomo.co/jp/news-press-releases/",
      },
      {
        text: "Ebook・ホワイトペーパー",
        link: "https://entomo.co/jp/resources/whitepaper-e-book/",
      },
    ],
  },
  {
    id: 3,
    contents: [
      {
        text: "なぜentomoが選ばれるのか",
        link: "https://entomo.co/jp/about/why-entomo/",
      },
      {
        text: "私たちのチーム",
        link: "https://entomo.co/jp/about/our-team/",
      },
      {
        text: "連絡先",
        link: "https://entomo.co/jp/contact/",
      },
      {
        text: "デモをリクエスト",
        link: "https://entomo.co/jp/request-demo/",
      },
    ],
  },
];

export { regions, innovationCenters, solutions, inspirations };
